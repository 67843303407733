<template>
  <div class="fullwidth align-left" @click="hideUserOptions()">
    <div class="content-header flex-row flex-row-between">
      <h1>Listado de horas de peon<span class="cantidad-resultados"> - Resultados: {{horasPeonFiltrados.length}}</span></h1>

<!--      <div class="content-header-buttons flex-row">-->
<!--        <div class="content-header-filter flex-row" @click="$emit('mostrarFiltro')">-->
<!--          <i class="icon-panel"></i>-->
<!--          <span>Filtrar</span>-->
<!--        </div>-->
<!--        <button class="btn-primary shadow animated" @click="$emit('crear')">Agregar nuevo</button>-->
<!--      </div>-->
    </div>

    <Chips
      :chips="chips"
      @removeChip="quitarFiltro"></Chips>

    <Table
      :options='tableOptions'
      @ver="ver"
      @editar="editar"></Table>
  </div>
</template>

<script>
import ajax from '@/ajax'
import { EventBus } from '@/event-bus.js'
import Table from '../../table'
import Chips from '../../chips'
import ConstantsHelper from '@/ConstantsHelper'

export default {
  name: 'listado',
  components: {
    Table,
    Chips
  },
  data () {
    return {
      horasPeon: [],
      horasPeonFiltrados: [],
      filtros: {
        text: '',
        estado: '1'
      },

      chips: {
        text: {
          show: false,
          indicador: 'Palabra Clave',
          chip: '',
          title: 'Eliminar este filtro'
        }
        // estado: {
        //   show: true,
        //   indicador: 'Estado',
        //   chip: 'Activos',
        //   title: 'Eliminar este filtro'
        // }
      },

      tableOptions: {
        columns: [
          {
            name: 'Valor',
            width: 90,
            rowProperty: 'valor',
            hideMobile: false
          }
        ],
        options: true,
        rows: [],
        buttons: [
          {
            name: 'EDITAR',
            fn: 'editar'
          }
        ]
      }
    }
  },
  methods: {
    // cambiarEstado (data) {
    //   let horaPeon = data.obj
    //   let index = data.index
    //   let action = data.action
    //   const actionName = (action === 1) ? 'Activará' : 'Desactivará'
    //   if (confirm(`Se ${actionName} la hora del peon. ¿Desea continuar?`)) {
    //     this.$emit('loading', true)
    //
    //     const nuevoEstado = (horaPeon.state === 1) ? 0 : 1
    //
    //     const nuevaHoraPeon = {
    //       id: horaPeon.id,
    //       valor: horaPeon.valor,
    //       estado: nuevoEstado
    //     }
    //
    //     ajax('PUT', 'https://api.gp-intra.com.ar/v1/horas-peon.php', { horaPeon: nuevaHoraPeon, action: 'cambiarEstado', usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
    //       .then((response) => {
    //         let type = 'error'
    //         if (response.status === 1) {
    //           this.horasPeonFiltrados[index].state = nuevoEstado
    //           this.filtrar()
    //           this.ordenarPorEstado()
    //           type = 'correct'
    //         }
    //         EventBus.$emit('generateToast', {
    //           message: response.message,
    //           type: type
    //         })
    //
    //         this.$emit('loading', false)
    //       })
    //       .catch(() => {
    //         this.$emit('loading', false)
    //       })
    //   }
    // },

    filtrar () {
      this.horasPeonFiltrados = this.horasPeon
      this.horasPeonFiltrados = this.filtrarText()
      // this.horasPeonFiltrados = this.filtrarEstado()

      // this.ordenarPorEstado()
    },
    filtrarText () {
      return this.horasPeonFiltrados.filter((hp) => {
        return (hp.valor.toLowerCase().indexOf(this.filtros.text) !== -1)
      })
    },
    // filtrarEstado () {
    //   return this.horasPeonFiltrados.filter((hp) => {
    //     return (this.filtros.estado === '' || hp.state === parseInt(this.filtros.estado))
    //   })
    // },
    filtroRapido (text) {
      this.filtros.text = text
      this.actualizarChips()
      this.filtrar()
    },

    quitarFiltro (field) {
      this.filtros[field] = ''
      this.filtrar()

      if (field === 'text') {
        EventBus.$emit('actualizarValorBuscado', '')
      }

      EventBus.$emit('actualizarFiltros', this.filtros)
    },

    // ordenarPorEstado () {
    //   this.horasPeonFiltrados.sort((a, b) => {
    //     if (a.state === 0) {
    //       return 1
    //     }
    //     if (b.state === 0) {
    //       return -1
    //     }
    //     return a.nombre - b.nombre
    //   })
    // },

    parseHorasPeon (horaPeon) {
      return {
        id: horaPeon.id,
        valor: horaPeon.valor,
        state: 1,
        // state: horaPeon.state,
        mostrarMenu: false,
        showToggleActivate: false
      }
    },
    hideUserOptions () {
      for (let horaPeon of this.horasPeon) {
        horaPeon.mostrarMenu = false
      }
    },
    ver (horaPeon) {
      this.$emit('ver', horaPeon)
    },
    editar (horaPeon) {
      this.$emit('editar', JSON.parse(JSON.stringify(horaPeon)))
    },

    actualizarChips () {
      this.chips.text.chip = this.filtros.text
      this.chips.text.show = this.filtros.text !== ''

      // this.chips.estado.chip = (this.filtros.estado === '1') ? 'Activos' : 'Desactivados'
      // this.chips.estado.show = this.filtros.estado !== ''
    },

    actualizarAltoTabla () {
      let header = document.getElementsByClassName('main-content-header')[0]
      let contentHeader = document.getElementsByClassName('content-header')[0]
      let chipsContainer = document.getElementsByClassName('chips-container')[0]

      if (header !== undefined && contentHeader !== undefined && chipsContainer !== undefined) {
        EventBus.$emit('actualizarAltoTabla', [
          header.getBoundingClientRect().height,
          contentHeader.getBoundingClientRect().height,
          chipsContainer.getBoundingClientRect().height,
          5
        ])
      }
    }
  },
  mounted () {
    // EventBus.$on('horaPeonAgregado', (horaPeon) => {
    //   this.horasPeon.push(horaPeon)
    //   this.filtrar()
    // })
    EventBus.$on('horaPeonEditado', (horaPeon) => {
      const index = this.horasPeon.findIndex(u => u.id === horaPeon.id)
      this.horasPeon[index] = horaPeon
      this.filtrar()
    })
    EventBus.$on('filtroRapidoHorapeon', this.filtroRapido)
    EventBus.$on('filtrar', (filtroData) => {
      this.filtros = filtroData
      this.actualizarChips()
      this.filtrar()
    })
    this.$emit('loading', true)

    ajax('GET', ConstantsHelper.getURLs().v1 + 'horas-peon.php?estado=%')
      .then((response) => {
        let type = 'error'
        if (response.code === 1) {
          this.horasPeon.push(this.parseHorasPeon(response.data))
          this.filtrar()
          this.$emit('loading', false)
          type = 'correct'
        } else {
          EventBus.$emit('generateToast', {
            message: response.response,
            type: type
          })
        }
      })
  },
  watch: {
    horasPeon () {
      this.actualizarAltoTabla()
    },
    horasPeonFiltrados () {
      this.tableOptions.rows = this.horasPeonFiltrados
    }
  }
}
</script>

<style>

</style>
