<template>
  <div class="fullheight">
    <div class="content-header flex-row flex-row-between">
      <h1>{{tituloComponente}}</h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row">
          <i class="icon-arrow-left"></i>
          <span @click="volverAtras">Volver atrás</span>
        </div>
<!--        <button v-if="!horaPeonSelected" class="btn-primary animated" @click="agregarHoraPeon">Guardar</button>-->
        <button v-if="horaPeonSelected && !view" class="btn-primary animated" @click="editar">Guardar</button>
        <button v-if="view" class="btn-primary animated" @click="cambiarAeditar">Editar</button>
      </div>
    </div>

    <div class="content-content flex-column flex-column-left flex-column-top">
      <form class="form-container fullwidth">

        <div class="flex-row flex-left flex-row-between">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container">
            <label class="form-field-indicador" for="hora-peon-selected-valor"
                   v-show="horaPeon.valor !== ''">Valor:</label>
            <input v-model="horaPeon.valor" v-validate="'required'" id="hora-peon-selected-valor" name="Valor" :disabled='view'
                   class="form-field form-field-nomargin" :class="{error: errors.has('Valor'), 'field-complete': horaPeon.valor !== ''}"
                   type="number" placeholder="Valor">
          </div>

        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import ajax from '@/ajax'
import ConstantsHelper from '@/ConstantsHelper'

export default {
  name: 'crearOEditar',
  props: ['view', 'edit', 'agregar', 'horaPeonSelected'],
  data () {
    return {
      horaPeon: {
        id: '',
        valor: '',
        mostrarMenu: false
      }
    }
  },
  methods: {
    volverAtras () {
      EventBus.$emit('toggleShow', true)
      this.initHoraPeon()
      this.$emit('mostrarListado')
    },
    initHoraPeon () {
      this.horaPeon = {
        id: '',
        valor: '',
        mostrarMenu: false
      }
    },
    agregarHoraPeon () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('POST', ConstantsHelper.getURLs().v1 + 'horas-peon.php', { horaPeon: this.horaPeon, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.horaPeon.id = response.id_inserted
                this.horaPeon.state = 1
                EventBus.$emit('horaPeonAgregado', this.horaPeon)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initHoraPeon()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })
              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    editar () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('PUT', ConstantsHelper.getURLs().v1 + 'horas-peon.php', { horaPeon: this.horaPeon, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.horaPeon.mostrarMenu = false
                EventBus.$emit('horaPeonEditado', this.horaPeon)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initHoraPeon()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })

              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    cambiarAeditar () {
      this.$emit('editar', this.horaPeon)
    },

    toggleHeader () {
      if (this.agregar || this.view || this.edit) {
        EventBus.$emit('toggleShow', false)
      } else {
        EventBus.$emit('toggleShow', true)
      }
    }
  },
  computed: {
    tituloComponente () {
      if (this.view) {
        return 'Visualizar costo hora de peon'
      } else {
        return (this.horaPeonSelected) ? 'Editar costo hora de peon' : 'Agregar costo hora de peon'
      }
    }
  },
  watch: {
    horaPeonSelected: {
      handler () {
        if (this.horaPeonSelected) {
          this.horaPeon = this.horaPeonSelected
        } else {
          this.initHoraPeon()
        }
      }
    },
    agregar () {
      this.toggleHeader()
    },
    view () {
      this.toggleHeader()
    },
    edit () {
      this.toggleHeader()
    }
  }
}
</script>
